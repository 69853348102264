export const SuperAdminMenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="fas fa-home"></i>,
  },
  {
    title: "Client",
    classsChange: "mm-collapse",
    // update:"New",
    iconStyle: <i className="fas fa-users" />,
    content: [
      {
        title: "Client List",
        to: "super-admin-client",
      },
    ],
  },

  {
    title: "users Category",
    classsChange: "mm-collapse",
    // update:"New",
    iconStyle: <i className="fas fa-users" />,
    content: [
      {
          title:'User Level',
          to:'user-level'
      },
      {
          title:'User category',
          to:'user-category'
      },
      {
        title: "User Category Type",
        to: "user-category-type",
      },
      {
        title: "User Sub Category",
        to: "user-sub-category",
      },
    ],
  },
];

export const AdminMenuList = [
  //Dashboard
  // {
  //   title: "Dashboard",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="fas fa-home"></i>,
  // },

  // {
  //   title: "Manage Registration",
  //   classsChange: "mm-collapse",
  //   // update:"New",
  //   iconStyle: <i className="fas fa-users" />,
  //   content: [
  //     {
  //       title: "Institution List",
  //       to: "Organization",
  //     },
  //     {
  //       title: "Individual  List",
  //       to: "Individual",
  //     },
  //   ],
  // },

  // {
  //   title: "Manage Verification",
  //   classsChange: "mm-collapse",
  //   // update:"New",
  //   iconStyle: <i className="fas fa-suitcase" />,
  //   content: [
  //     {
  //       title: "Institution  Verification List",
  //       to: "verified-insitute-user",
  //     },
  //     {
  //       title: "Individual Verification List",
  //       to: "verified-individual-user",
  //     },
  //   ],
  // },
  // {
  //   title: "Communication",
  //   to: "communication",
  //   iconStyle: <i class="bi bi-chat-fill"></i>,
  //   content: [
  //     {
  //       title: "Communication List",
  //       to: "communication/communication-list",
  //     },
  //     {
  //       title: "Website Notification List",
  //       to: "communication/website-notification-list",
  //     },
  //   ],
  // },
  // {
  //   title: "Event Calendar",
  //   to: "event/event-calendar",
  //   iconStyle: <i class="bi bi-calendar-event-fill"></i>,
  //   content: [
  //     {
  //       title: "Event Calendar",
  //       to: "event/event-calendar",
  //     },
  //   ],
  // },
  // {
  //   title: "Grievance Management",
  //   to: "communication",
  //   iconStyle: <i class="bi bi-chat-fill"></i>,
  //   content: [
  //     {
  //       title: "Grievance Management",
  //       to: "grievance-management",
  //     },
  //   ],
  // },
  {
    title: "Venue Management",
    to: "venue",
    iconStyle: <i class="bi bi-chat-fill"></i>,
    content: [
      {
        title: "Venue",
        to: "venue",
      },
    ],
  },
  {
    title: "Access Permission",
    to: "communication",
    iconStyle: <i class="bi bi-chat-fill"></i>,
    content: [
      {
        title: "Access Permission",
        to: "permission",
      },
      {
        title: "Permit Users List",
        to: "permitusers",
      },
    ],
  },
  {
    title: "users Category",
    classsChange: "mm-collapse",
    // update:"New",
    iconStyle: <i className="fas fa-users" />,
    content: [
      // {
      //     title:'User Level',
      //     to:'user-level'
      // },
      // {
      //     title:'User category',
      //     to:'user-category'
      // },
      {
        title: "User Category Type",
        to: "user-category-type",
      },
      {
        title: "User Sub Category",
        to: "user-sub-category",
      },
    ],
  },
  {
    title: "Add Users",
    classsChange: "mm-collapse",
    // update:"New",
    iconStyle: <i className="fas fa-users" />,
    content: [
      {
        title: "Add New User",
        to: "create-new-user",
      },
      {
        title: "Approve Users",
        to: "approve-users",
      },
    ],
  },

];
