import React, { useContext, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { PermissionContext } from "../permission/PermissionContext";
import axiosInstance from "../../../services/AxiosInstance";

const ExcelUsers = () => {
  const [data, setData] = useState([]);
  const [imageUploadsComplete, setImageUploadsComplete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSport, setSelectedSport] = useState("");
  const fileInputRef = useRef(null);
  const { sports, userTypes } = useContext(PermissionContext);

  const resetInput = () => {
    setData([]);
    setSelectedCategory("");
    setSelectedSport("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataForSubmission = data.map((item, index) => ({
      firstName: item["First Name"],
      middleName: item["Middle Name"],
      lastName: item["Last Name"],
      email: item["Email"],
      mobile: item["Mobile"],
      sub_category_type: item["Category ID"],
      gender: item["Gender"],
      sportId: item["Sport ID"],
      photo: item["PhotoPath"] || null,
      sr_no: index + 1,
    }));

    console.log("Submitting data:", dataForSubmission);

    axiosInstance
      .post("/user/bulk-candidate", dataForSubmission)
      .then((response) => {
        console.log("Response:", response.data);
        resetInput();
      })
      .catch((error) => {
        console.error(
          "Error submitting data:",
          error.response?.data || error.message
        );
      });
  };

  const handleExcelImport = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const rawData = new Uint8Array(event.target.result);
        const workbook = XLSX.read(rawData, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const dataWithPhoto = jsonData.map((item) => ({
          ...item,
          "Category ID": selectedCategory,
          "Sport ID": selectedSport,
        }));

        setData(dataWithPhoto);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("id", index + 1);
      axiosInstance
        .post("/uploads", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const updatedData = [...data];
          const filePath = Object.values(response?.data?.data)?.[0];
          updatedData[index]["PhotoPath"] = filePath; 
          updatedData[index]["Photo"] = URL.createObjectURL(file);
          setData(updatedData);
  
          const allImagesUploaded = updatedData.every(
            (item) => item["PhotoPath"] && item["PhotoPath"] !== "No Image"
          );
          setImageUploadsComplete(allImagesUploaded);
        })
        .catch((error) => {
          console.error(
            "Error uploading image:",
            error.response?.data || error.message
          );
        });
    }
  };
  

  const handleRemoveImage = (index) => {
    const updatedData = [...data];
    const filePath = updatedData[index]["PhotoPath"];

    if (filePath) {
      axiosInstance
        .delete("/uploads", {
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            filename: filePath,
          },
        })
        .then((response) => {
          console.log("Image deleted successfully:", response.data);

          updatedData[index]["Photo"] = null;
          updatedData[index]["PhotoPath"] = null;
          setData(updatedData);
        })
        .catch((error) => {
          console.error(
            "Error deleting image:",
            error.response?.data || error.message
          );
        });
    } else {
      console.error("No file path found for deletion.");
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSportChange = (e) => {
    setSelectedSport(e.target.value);
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <label className="mb-1">
            <h3>
              <strong>Import from Excel</strong>
            </h3>
          </label>
          <div className="pb-2 row align-items-end justify-content-center">
            <div className="col-md-3">
              <div className="form-group mb-3">
                <strong>
                  Select Sub Category<span className="text-red">*</span>
                </strong>
                <select
                  name="categoryid"
                  className="form-control"
                  required
                  onChange={handleCategoryChange}
                  value={selectedCategory}
                >
                  <option value="">Select User Sub Type</option>
                  {userTypes.map((type) => (
                    <option
                      key={type.sub_category_id}
                      value={type.sub_category_id}
                    >
                      {type.sub_category_name_view}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mb-3">
                <strong>Select Sport</strong>
                <select
                  name="sportid"
                  className="form-control"
                  onChange={handleSportChange}
                  value={selectedSport}
                >
                  <option value="">Select Sport</option>
                  {sports.map((type) => (
                    <option key={type.sport_id} value={type.sport_id}>
                      {type.sport_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mb-3 d-flex flex-column gap-2 w-100">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip-2" hidden={selectedCategory}>
                      Category must be selected
                    </Tooltip>
                  }
                >
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="form-control"
                    accept=".xlsx, .xls"
                    onChange={handleExcelImport}
                    style={{ padding: "5px" }}
                    disabled={!selectedCategory}
                  />
                </OverlayTrigger>
              </div>
            </div>

            <div className="col-md-3 form-group mb-3 d-flex gap-2" style={{height: "50px"}}>
              <Button onClick={resetInput} variant="primary" className="mt-2 btn btn">
                Reset
              </Button>
              <Button
                onClick={() =>
                  window.open("/path-to-sample-excel-file.xlsx", "_blank")
                }
                variant="secondary"
                className="mt-2"
              >
                 Sample Excel
              </Button>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="w-100 table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>First Name</th>
                    <th>Middle Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Category ID</th>
                    <th>Gender</th>
                    <th>Sport ID</th>
                    <th>Photo</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item["First Name"] || "N/A"}</td>
                        <td>{item["Middle Name"] || "N/A"}</td>
                        <td>{item["Last Name"] || "N/A"}</td>
                        <td>{item["Email"] || "N/A"}</td>
                        <td>{item["Mobile"] || "N/A"}</td>
                        <td>
                          {userTypes.find(
                            (cat) => cat.sub_category_id == item["Category ID"]
                          )?.sub_category_name_view || "N/A"}
                        </td>
                        <td>
                          {item["Gender"] == 1 ? "Male" : "Female" || "N/A"}
                        </td>
                        <td>
                          {sports.find(
                            (cat) => cat.sport_id == item["Sport ID"]
                          )?.sport_name || "N/A"}
                        </td>
                        <td>
                          <div className="d-flex" style={{ gap: "10px" }}>
                            {item["Photo"] ? (
                              <img
                                src={item["Photo"]}
                                alt="User"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              "No Image"
                            )}
                            <div
                              className="d-flex"
                              style={{ flexDirection: "column" }}
                            >
                              {!item["PhotoPath"] ? ( 
                                <input
                                  type="file"
                                  onChange={(e) => handleImageChange(e, index)}
                                  accept="image/*"
                                  style={{ margin: "10px" }}
                                />
                              ) : (
                                <Button
                                  variant="danger"
                                  onClick={() => handleRemoveImage(index)}
                                  style={{ margin: "10px" }}
                                >
                                  Remove Image
                                </Button>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="11" className="text-center">
                        No data available. Please upload an Excel file.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-submit">
                    {!imageUploadsComplete
                      ? "All images must be uploaded before submitting."
                      : ""}
                  </Tooltip>
                }
              >
                <button
                  type="submit"
                  className="btn btn-primary btn-md"
                  disabled={!imageUploadsComplete}
                >
                  Submit
                </button>
              </OverlayTrigger>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExcelUsers;
