import { createContext, useContext, useEffect, useState } from "react";
import { PermissionContext } from "../permission/PermissionContext";
import axiosInstance from "../../../services/AxiosInstance";

export const PermitUserContext = createContext();

export const PermitUserProvider = ({ children }) => {
  const { getPermissionData } = useContext(PermissionContext);
  const [permitUserData, setPermitUserData] = useState();
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [userType, setUserType] = useState(null);
  const [gender, setGender] = useState(null);
  const [sport, setSport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrPage] = useState(1);
  const getPermitUserData = async (
    userType = null,
    sport = null,
    gender = null
  ) => {
    setIsLoading(true);
    setPermitUserData([]);
    const permissionData = await getPermissionData(userType, sport, gender);
    const isPermissionAvailable = permissionData?.totalItems;
    if (!isPermissionAvailable) {
      return false;
    }

    let permissionId = permissionData?.data[0]?.id;
    const params = new URLSearchParams();
    if (limit) params.append("limit", limit);
    if (page) params.append("page", page);
    if (!userType) {
      permissionId = 0;
      setPermitUserData([]);
      setTotalPages(0);
      setCurrPage(1);
      setIsLoading(false);
      return;
    }
    const response = await axiosInstance.get(
      `/permit/users/${permissionId}?${params.toString()}`
      // `/permit/users/${0}?${params.toString()}`
    );
    setPermitUserData(response.data);
    setTotalPages(response.data.pagination.totalPages);
    setCurrPage(response.data.pagination.currentPage);
    setIsLoading(false);
    return response.data.data;
  };
  useEffect(() => {
    getPermitUserData(userType, sport, gender);
  }, [page, limit, setPage, setLimit, userType]);
  return (
    <PermitUserContext.Provider
      value={{
        getPermitUserData,
        limit,
        setLimit,
        totalPages,
        page,
        setPage,
        currentPage,
        permitUserData,
        setUserType,
        setGender,
        setSport,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </PermitUserContext.Provider>
  );
};
