import { useFormik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import * as Yup from "yup";
import notify from "../../../utils/notification";

import axiosInstance from "../../../services/AxiosInstance";

const AddSubCategoryModal = ({
  showAddModal,
  setShowAddModal,
  categoryTypeListData,
  CategorySubListApi,
}) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      categoryName: "",
      categoryid: "",
      slug: "",
      status: "",
      category_name_view: "",
    },
    validationSchema: Yup.object({
      categoryName: Yup.string().required("Please fill in the category name"),
      categoryid: Yup.string().required("Please select a category type"),
      // slug: Yup.string().required("Please fill in the slug"),
      status: Yup.string().required("Please fill in the status"),
      // category_name_view: Yup.string().required(
      //   "Please fill in the category name view"
      // ),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const newContent = {
        sub_category_name: values.categoryName,
        category_id: values.categoryid,
        // category_name_view: values.category_name_view,
        active_status: values.status,
        user_level_id: 5,
        // url_slug: values.slug,
      };

      try {
        setLoading(true);
        const response = await axiosInstance.post("/user-sub-type", newContent);

        if (response?.data?.statusCode === 201) {
          //   swal('success','',`${response?.data?.message}`);
          swal("Success", `${response?.data?.message}`, "success");
          CategorySubListApi();
          setShowAddModal(false);
          resetForm();
        } else {
          notify("error", response.data.message);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          notify("error", error.response.data.message);
        } else {
          notify("error", "An unexpected error occurred. Please try again.");
        }
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });
  return (
    <>
      <Modal
        className="modal fade"
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add SubCategory</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowAddModal(false)}
                  data-dismiss="modal"
                >
                  <span></span>
                </button>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label className="text-black font-w500">
                          Select Category Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="contact-name">
                          <select
                            name="categoryid"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.categoryid}
                            className="form-control"
                          >
                            <option value="">Select category</option>
                            {categoryTypeListData?.data?.map((item) => (
                              <option key={item?.category_id} value={item?.category_id}>
                                {item?.category_name_view}
                              </option>
                            ))}
                          </select>
                          {formik.touched.categoryid &&
                          formik.errors.categoryid ? (
                            <span className="validation-text text-danger">
                              {formik.errors.categoryid}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label className="text-black font-w500">
                          Enter Sub Category Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="categoryName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.categoryName}
                            placeholder="Enter category name"
                          />
                          {formik.touched.categoryName &&
                          formik.errors.categoryName ? (
                            <span className="validation-text text-danger">
                              {formik.errors.categoryName}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    {/* <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label className="text-black font-w500">
                          Enter Category Name View{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="category_name_view"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.category_name_view}
                            placeholder="Enter category name view"
                          />
                          {formik.touched.category_name_view &&
                          formik.errors.category_name_view ? (
                            <span className="validation-text text-danger">
                              {formik.errors.category_name_view}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label className="text-black font-w500">
                          Enter Slug Name <span className="text-danger">*</span>
                        </label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="slug"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.slug}
                            placeholder="Enter slug name"
                          />
                          {formik.touched.slug && formik.errors.slug ? (
                            <span className="validation-text text-danger">
                              {formik.errors.slug}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div> */}

                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label className="text-black font-w500">
                          Status <span className="text-danger">*</span>
                        </label>
                        <div className="contact-name">
                          <select
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                            className="form-control"
                          >
                            <option value="">Select status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                          {formik.touched.status && formik.errors.status ? (
                            <span className="validation-text text-danger">
                              {formik.errors.status}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Adding..." : "Add"}
                </button>
                <button
                  type="button"
                  onClick={() => setShowAddModal(false)}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"></i> Discard
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddSubCategoryModal;
