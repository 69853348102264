import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";
import { PermissionContext } from "./PermissionContext";

const CreateModal = ({ show, handleClose, userType, gender, sport }) => {
  // const [venues, setVenues] = useState([]);
  const {venues, getPermissionData} = useContext(PermissionContext);
  const [zones, setZones] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [selectedZones, setSelectedZones] = useState([]);
  const [venue_color_code, setvenue_color_code] = useState("#00000");
  const [categoryCode, setCategoryCode] = useState("");
  const [errors, setErrors] = useState({});
  const [infinitySelected, setInfinitySelected] = useState(false);
  
  // const fetchVenues = async () => {
  //   try {
  //     const res = await axiosInstance.get("/venue");
  //     const venueOptions = res.data.data.map((venue) => ({
  //       value: venue.id,
  //       label: venue.name,
  //     }));
  //     setVenues(venueOptions);
  //   } catch (error) {
  //     console.error("Error fetching venues:", error);
  //   }
  // };

  const fetchZones = async (venueId) => {
    try {
      const res = await axiosInstance.get(`/venue/${venueId}/zones`);
      const zoneOptions = res.data.data.zones.map((zone) => ({
        value: zone.id,
        label: zone.name,
      }));
      setZones([{ value: "infinity", label: "Infinity" }, ...zoneOptions]);
    } catch (error) {
      console.error("Error fetching zones:", error);
    }
  };

  useEffect(() => {
    // fetchVenues();
  }, []);

  const handleVenueChange = (selectedOption) => {
    setSelectedVenue(selectedOption);
    setZones([]);
    setSelectedZones([]);
    setInfinitySelected(false);
    if (selectedOption) fetchZones(selectedOption.value);
  };

  const handleZoneChange = (selectedOptions) => {
    const isInfinitySelected = selectedOptions?.some(
      (zone) => zone.value === "infinity"
    );

    setInfinitySelected(isInfinitySelected);

    if (isInfinitySelected) {
      setSelectedZones([{ value: "infinity", label: "Infinity" }]);
    } else {
      setSelectedZones(selectedOptions);
    }
  };

  const handleColorChange = (e) => {
    setvenue_color_code(e.target.value);
  };

  const handleCategoryCodeChange = (e) => {
    setCategoryCode(e.target.value);
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!selectedVenue) validationErrors.venue = "Venue is required.";
    if (!selectedZones || selectedZones.length === 0)
      validationErrors.zones = "At least one zone must be selected.";
    if (!venue_color_code) validationErrors.venue_color_code = "Color code is required.";
    if (!categoryCode) validationErrors.categoryCode = "Category code is required.";

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const allZoneIds = zones
          .filter((zone) => zone.value !== "infinity")
          .map((zone) => zone.value);

        const postData = {
          venueid: selectedVenue.value,
          zoneIds: infinitySelected ? allZoneIds : selectedZones.map((zone) => zone.value),
          venueColorCode:venue_color_code,
          sportsNameId: sport, 
          categoryCode,
          userType,
          gender,
          allZone: infinitySelected,
        };

        const response = await axiosInstance.post("/permission", postData);
        console.log("Permission created:", response.data);
        setSelectedVenue(null);
        setSelectedZones([]);
        setvenue_color_code('#000000');
        setCategoryCode("");
        getPermissionData()
        swal("Success", "Permission created successfully!", "success");
        handleClose();
      } catch (error) {
        console.error("Error creating permission:", error);
        swal("Error", "Permission Already Exist!", "error");
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <form>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Create Permission</h4>
          <button type="button" className="btn-close" onClick={handleClose}>
            <span></span>
          </button>
        </div>

        <div className="modal-body">
          <div className="add-contact-box">
            <div className="add-contact-content">
              <div className="row">
                {/* Venue Selection */}
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Select Venue <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    options={venues}
                    placeholder="Select Venue"
                    onChange={handleVenueChange}
                    value={selectedVenue}
                  />
                  {errors.venue && <Alert variant="danger">{errors.venue}</Alert>}
                </Form.Group>

                {/* Zone Selection */}
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Select Zone <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    isMulti
                    options={zones}
                    placeholder={selectedVenue ? "Select Zone" : "Select a Venue First"}
                    onChange={handleZoneChange}
                    value={selectedZones}
                    isDisabled={!selectedVenue}
                  />
                  {errors.zones && <Alert variant="danger">{errors.zones}</Alert>}
                </Form.Group>
              </div>

              <div className="row">
                {/* Color Code */}
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Color Code <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    type="color"
                    value={venue_color_code}
                    onChange={handleColorChange}
                  />
                  <small className="text-muted">
                    Selected Color: <strong>{venue_color_code}</strong>
                  </small>
                  {errors.venue_color_code && <Alert variant="danger">{errors.venue_color_code}</Alert>}
                </Form.Group>

                {/* Category Code */}
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Category Code <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Category Code"
                    value={categoryCode}
                    onChange={handleCategoryCodeChange}
                  />
                  {errors.categoryCode && <Alert variant="danger">{errors.categoryCode}</Alert>}
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateModal;
