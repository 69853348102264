import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import swal from "sweetalert";
import axiosInstance from "../../../../services/AxiosInstance";
import SportsFacilitiesMultiSelect from "./SportsFacilitiesMultiSelect";
import { BASE_URL } from "../../../../config/adminUrlConfig";

const generateInitialValue = (editData = {}) => ({
  venue_code: editData?.venue_code || "",
  venue_name: editData?.name || "",
  latitude: editData?.latitude || "",
  longitude: editData?.longitude || "",
  range: editData?.range || "",
  image: null,
  description: editData?.description || "",
  capacity: editData?.capacity || "",
  sportsFacilities: editData?.sports_facilities || [],
  ownerName: editData?.venue_owner_name || "",
  ownerPhone: editData?.venue_owner_phone || "",
  ownerEmail: editData?.venue_owner_email || "",
});

const VenueModal = ({ show, handleClose, fetchData, editData }) => {
  const [data, setData] = useState(generateInitialValue(editData));
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(editData?.image || null);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (editData) {
      setData(generateInitialValue(editData));
      setFileUrl(`${BASE_URL}/uploads/${editData?.imageURL}` || null);
    }
  }, [editData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
      setFileUrl(URL.createObjectURL(file));
      setErrors((prevErrors) => ({ ...prevErrors, image: "" }));
    } else {
      setSelectedFile(null);
      setFileUrl(null);
    }
  };

  const handleFilePreview = () => {
    if (fileUrl) window.open(fileUrl, "_blank");
  };

  const handleReset = () => {
    setData(generateInitialValue());
    setSelectedFile(null);
    setFileUrl(null);
    setErrors({});
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  const validateForm = () => {
    const validationErrors = {};

    if (!data.venue_code) validationErrors.venue_code = "Venue Code is required.";
    if (!data.venue_name) validationErrors.venue_name = "Venue Name is required.";
    if (!/^(-?\d+(\.\d+)?)$/.test(data.latitude)) {
      validationErrors.latitude = "Latitude must be a valid number.";
    }
    if (!/^(-?\d+(\.\d+)?)$/.test(data.longitude)) {
      validationErrors.longitude = "Longitude must be a valid number.";
    }
    if (!data.range || isNaN(data.range) || parseFloat(data.range) <= 0) {
      validationErrors.range = "Range must be a positive number.";
    }
    if (!data.capacity || isNaN(data.capacity) || parseInt(data.capacity) <= 0) {
      validationErrors.capacity = "Capacity must be a positive number.";
    }
    if (!data.description) validationErrors.description = "Description is required.";
    if (!data.ownerName) validationErrors.ownerName = "Owner Name is required.";
    if (!data.ownerPhone || !/^\d+$/.test(data.ownerPhone)) {
      validationErrors.ownerPhone = "Owner Phone must contain only numbers.";
    }
    if (!data.ownerEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.ownerEmail)) {
      validationErrors.ownerEmail = "Invalid email format.";
    }
    if (!fileUrl && !selectedFile && !editData?.image) {
      validationErrors.image = "Image is required.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      swal("Validation Error", "Please fix the highlighted fields.", "error");
      return;
    }

    const payload = {
      venue_code: data.venue_code,
      name: data.venue_name,
      latitude: data.latitude,
      longitude: data.longitude,
      range: data.range,
      description: data.description,
      capacity: data.capacity,
      venue_owner_name: data.ownerName,
      venue_owner_phone: data.ownerPhone,
      venue_owner_email: data.ownerEmail,
      sports_facilities: data.sportsFacilities,
    };

    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      if (Array.isArray(payload[key])) {
        payload[key].forEach((item) => formData.append(`${key}[]`, item));
      } else {
        formData.append(key, payload[key]);
      }
    });

    if (selectedFile) {
      formData.append("image", selectedFile);
    }

    try {
      if (editData) {
        await axiosInstance.patch(`/venue/${editData.id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        swal("Venue Updated Successfully!", "", "success");
      } else {
        await axiosInstance.post("/venue", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        swal("Venue Created Successfully!", "", "success");
      }
      fetchData();
      handleClose();
      handleReset();
    } catch (error) {
      const message = error.response?.data?.message || "An unexpected error occurred.";
      swal("Error!", message, "error");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">
            {editData ? "Edit Venue" : "Add New Venue"}
          </h4>
          <button type="button" className="btn-close" onClick={handleClose}>
            <span></span>
          </button>
        </div>

        <div className="modal-body">
          <div className="add-contact-box">
            <div className="add-contact-content">
              {/* Venue Code and Name */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Venue Code <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="venue_code"
                    value={data.venue_code}
                    onChange={handleInputChange}
                    isInvalid={!!errors.venue_code}
                    className={errors.venue_code ? "border-danger" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.venue_code}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Venue Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div>

                  <Form.Control
                    type="text"
                    name="venue_name"
                    value={data.venue_name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.venue_name}
                    className={errors.venue_name ? "border-danger" : ""}
                    />
                    </div>
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.venue_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {/* Latitude and Longitude */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Latitude <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="latitude"
                    value={data.latitude}
                    onChange={handleInputChange}
                    isInvalid={!!errors.latitude}
                    className={errors.latitude ? "border-danger" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.latitude}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Longitude <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="longitude"
                    value={data.longitude}
                    onChange={handleInputChange}
                    isInvalid={!!errors.longitude}
                    className={errors.longitude ? "border-danger" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.longitude}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {/* Range and Capacity */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Range (in meters) <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="range"
                    value={data.range}
                    onChange={handleInputChange}
                    isInvalid={!!errors.range}
                    className={errors.range ? "border-danger" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.range}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Capacity <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="capacity"
                    value={data.capacity}
                    onChange={handleInputChange}
                    isInvalid={!!errors.capacity}
                    className={errors.capacity ? "border-danger" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.capacity}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {/* Image Upload */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Image <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div>
                  <Form.Control
                    type="file"
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={handleFileChange}
                    isInvalid={!!errors.image}
                    className={errors.image ? "border-danger" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.image}
                  </Form.Control.Feedback>
                  {fileUrl && (
                    <Button
                      variant="secondary"
                      className="mt-2"
                      onClick={handleFilePreview}
                    >
                      Preview
                    </Button>
                  )}
                </div>
              </Form.Group>

              {/* Description */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Description <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={data.description}
                  onChange={handleInputChange}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Sports/Facilities Selection */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Select Sports/Facilities <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <SportsFacilitiesMultiSelect
                  onChange={(selected) =>
                    setData((prevData) => ({
                      ...prevData,
                      sportsFacilities: selected,
                    }))
                  }
                  selectedValues={data.sportsFacilities}
                />
              </Form.Group>

              {/* Venue Owner Details */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Venue Owner Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="ownerName"
                    value={data.ownerName}
                    onChange={handleInputChange}
                    isInvalid={!!errors.ownerName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.ownerName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Venue Owner Phone Number <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="ownerPhone"
                    value={data.ownerPhone}
                    onChange={handleInputChange}
                    isInvalid={!!errors.ownerPhone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.ownerPhone}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>
                  Venue Owner Email Address <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="ownerEmail"
                  value={data.ownerEmail}
                  onChange={handleInputChange}
                  isInvalid={!!errors.ownerEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.ownerEmail}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <Button variant="secondary" onClick={handleReset}>
            Reset
          </Button>
          <Button type="submit" variant="primary">
            {editData ? "Update Venue" : "Save Venue"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default VenueModal;
