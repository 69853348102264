import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import axiosInstance from "../../../../src/services/AxiosInstance";
import CreateModal from "./CreateModal";
import EditModal from "./EditModal";
import { PermissionContext } from "./PermissionContext";
import Pagination from "../permitUsers/Pagination";

const PermissionList = () => {
  // const [permissionData, setPermissionData] = useState([]);
  const {
    userType: userTypes,
    sport: sports,
    permissionList,
    totalPages,
    currentPage,
    setCurrentPage,
    limit,
    setLimit
  } = useContext(PermissionContext);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  console.log(permissionList);
  // const fetchPermissions = async (page = 1, limit = pageSize) => {
  //   setLoading(true);
  //   try {
  //     const params = { page, limit };
  //     if (userType) params.userType = userType;
  //     if (sport) params.sport = sport;
  //     if (gender) params.gender = gender;
  //     // console.log("Great bro");
  //     const response = await axiosInstance.get("/permission", { params });
  //     const { data, totalPages } = response.data;

  //     // setPermissionList(data || []);
  //     setTotalPages(totalPages || 1);
  //     setCurrentPage(page);
  //   } catch (error) {
  //     console.error("Error fetching permissions:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   // fetchPermissions(currentPage, pageSize);
  // }, [userType, sport, gender, currentPage, pageSize]);

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleEditPermission = (item) => {
    setEditData(item);
    console.log(item);
    setShowEditModal(true);
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>Permission List</h5>
          </div>

          <div className="pb-2 row">
            <div className="col-md-1">
              <select
                className="form-control form-control-sm"
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>

          <div className="w-100 table-responsive">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>User Type</th>
                    <th>Gender</th>
                    <th>Sport</th>
                    <th>Venue</th>
                    <th>Zones</th>
                    <th>Colour Code</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {permissionList?.data?.length > 0 ? (
                    permissionList?.data?.map((item, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * limit + index + 1}</td>
                        <td>
                          {item.user_category_sub_type.sub_category_name_view ||
                            "N/A"}
                        </td>
                        <td>
                          {item.gender === 1
                            ? "Male"
                            : item.gender === 2
                            ? "Female"
                            : item.gender === 3
                            ? "Both"
                            : "N/A"}
                        </td>
                        <td>{item?.ref_sports_name?.sport_name || "N/A"}</td>
                        <td>{item?.Venue?.name || "N/A"}</td>
                        <td>
                          {item?.Zone.map((zone) => zone?.name)?.join(", ") ||
                            "N/A"}
                        </td>
                        <td>
                          {item.venue_color_code || "N/A"}
                          <span
                            style={{
                              display: "inline-block",
                              backgroundColor:
                                item?.venue_color_code || "#ffffff",
                              height: "20px",
                              width: "50px",
                              marginLeft: "5px",
                              borderRadius: "20%",
                              border: "1px solid #000",
                            }}
                          ></span>
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => handleEditPermission(item)}
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No Permissions Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>

          {/* {permissionList?.data?.length > 0 && (
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="btn btn-sm btn-secondary me-2"
                disabled={currentPage === 1}
                onClick={() => handlePageChange("prev")}
              >
                Previous
              </Button>
              <span className="mx-2">
                Page {currentPage} of {totalPages}
              </span>
              <Button
                className="btn btn-sm btn-secondary ms-2"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange("next")}
              >
                Next
              </Button>
            </div>
          )} */}

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>

      <CreateModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        // userType={userType}
        // gender={gender}
        // onSubmit={() => fetchPermissions(currentPage, pageSize)}
      />

      <EditModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        editData={editData}
        // onSubmit={() => fetchPermissions(currentPage, pageSize)}
        // userType={userType}
      />
    </div>
  );
};

export default PermissionList;
