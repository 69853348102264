import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";

const ShareForm = ({ addCard, setAddCard }) => {
  const [subtypes, setSubTypes] = useState();
  const [form, setForm] = useState({ email: "", name: "" });
  useEffect(() => {
    const call = async () => {
      const response = await axiosInstance.get("/user-sub-type");
      console.log(response.data);
      setSubTypes(response.data);
    };
    call();
  }, []);
  const shareOnEmail = async (e) => {
    e.preventDefault();
    const response = await axiosInstance.post("/email/send-email", {
      to: {
        name: form.name,
        email: form.email,
      },
    });
    if(response.status == 200){
        swal("Success", "Form Shared Successfully", "success");
        setAddCard(false);
    }

  };
  return (
    <Modal
      className="modal fade"
      show={addCard}
      onHide={() => setAddCard(false)}
    >
      <div className="" role="document">
        <div className="">
          <form onSubmit={shareOnEmail}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Share Manual Form</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setAddCard(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row">
                    

                    <div className="col-lg-6">
                      <label className="text-black font-w500">
                        Enter User Name
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        name="text"
                        type="name"
                        placeholder="Enter Name"
                        value={form.name}
                        onChange={(e) => {
                          setForm((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }));
                        }}
                      ></input>
                    </div>
                    <div className="col-lg-6">
                      <label className="text-black font-w500">
                        Enter User Email
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        name="email"
                        type="email"
                        placeholder="Enter Email"
                        value={form.email}
                        onChange={(e) => {
                          setForm((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }));
                        }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={false}
                onClick={shareOnEmail}
              >
                Share
              </button>
              <button
                type="button"
                onClick={() => setAddCard(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Discard
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ShareForm;
