import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRCodeComponent = ({ data }) => {
  const qrData = {
    userId: data.user_id,
    name: data.full_name,
    gender: data.gender == 1 ? "Male" : "Female",
    sport: data.sport_name,
    venue: data.venue_name,
    zones: data.zone_names,
  };

  const qrDataString = JSON.stringify(qrData);

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <QRCodeCanvas
        value={qrDataString}
        size={128}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"H"}
      />
    </div>
  );
};

export default QRCodeComponent;
