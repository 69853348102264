import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import axiosInstance from "../../../../services/AxiosInstance";
import ZoneModal from "./zonesModal";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";

const ZoneList = ({ venueId, fetchZones, zones, setZones }) => {
  const [venueName, setVenueName] = useState("Unknown"); // State for venue name
  const [showZoneModal, setShowZoneModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  // Pagination state
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);

  const handleClose = () => {
    setShowZoneModal(false);
    setEditData(null);
  };

  const handleShow = () => setShowZoneModal(true);

  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleDeleteModalShow = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const fetchVenueDetails = async () => {
    try {
      const response = await axiosInstance.get(`/venue/${venueId}/zones`);
      const venueData = response.data?.data;
      setVenueName(venueData?.name || "Unknown"); // Set the venue name dynamically
      setZones(venueData?.zones || []);
      setTotalPages(Math.ceil((venueData?.zones?.length || 0) / itemsPerPage));
    } catch (error) {
      console.error("Error fetching venue details:", error);
      swal("Error!", "Failed to fetch venue details.", "error");
    }
  };

  useEffect(() => {
    fetchVenueDetails();
  }, [venueId]);

  useEffect(() => {
    fetchZones();
  }, [activePage, venueId]);

  const editZone = (zone) => {
    setEditData(zone);
    handleShow();
  };

  const handleDeleteZone = async () => {
    try {
      await axiosInstance.delete(`/zones/${deleteId}`);
      swal("Zone Deleted!", "", "success");
      fetchVenueDetails(); // Refresh venue details and zones
      setDeleteId(null);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting zone:", error);
      swal("Error!", "Failed to delete the zone.", "error");
    }
  };

  const handlePreviousPage = () => {
    if (activePage > 1) setActivePage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (activePage < totalPages) setActivePage((prev) => prev + 1);
  };

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* Header Section with Venue Name, Back Button, and Add Zones Button */}
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="mb-0">Venue: {venueName}</h4> {/* Dynamic Venue Name */}
              <div>
                <Button
                  variant="secondary"
                  onClick={() => navigate(-1)} // Navigate to the previous page
                  className="btn-sm me-2"
                >
                  Back
                </Button>
                <Button variant="primary" onClick={handleShow} className="btn-sm">
                  Add Zones
                </Button>
              </div>
            </div>

            {/* Zones Table */}
            <div className="w-100 table-responsive">
              <table className="display w-100 dataTable">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Zone Code</th>
                    <th>Zone Name</th>
                    <th>Capacity</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {zones.length > 0 ? (
                    zones.map((zone, index) => (
                      <tr key={zone.id}>
                        <td>{(activePage - 1) * itemsPerPage + index + 1}</td>
                        <td>{zone.zone_code}</td>
                        <td>{zone.name}</td>
                        <td>{zone.capacity}</td>
                        <td>
                          <i
                            className="fas fa-pen cursor-pointer"
                            onClick={() => editZone(zone)}
                          ></i>
                        </td>
                        <td>
                          <i
                            className="fas fa-trash text-danger cursor-pointer"
                            onClick={() => handleDeleteModalShow(zone.id)}
                          ></i>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No Zones Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* Pagination Controls */}
              <div className="pagination-controls d-flex align-items-center justify-content-center gap-2 mt-3">
                <Button
                  variant="primary"
                  disabled={activePage === 1}
                  onClick={handlePreviousPage}
                  className="px-3"
                >
                  Previous
                </Button>
                {Array.from(
                  { length: Math.min(5, totalPages) },
                  (_, i) => i + activePage - Math.floor((activePage - 1) % 5)
                )
                  .filter((page) => page > 0 && page <= totalPages)
                  .map((page) => (
                    <Button
                      key={page}
                      variant={page === activePage ? "dark" : "light"}
                      className="px-3"
                      onClick={() => setActivePage(page)}
                    >
                      {page}
                    </Button>
                  ))}
                <Button
                  variant="primary"
                  disabled={activePage === totalPages}
                  onClick={handleNextPage}
                  className="px-3"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showZoneModal && (
        <ZoneModal
          show={showZoneModal}
          editData={editData}
          handleClose={handleClose}
          fetchZones={fetchZones}
          venueId={venueId}
        />
      )}

      {showDeleteModal && (
        <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this zone? This action cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteModalClose}>
              No
            </Button>
            <Button variant="danger" onClick={handleDeleteZone}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ZoneList;
