import React, { useContext, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import swal from "sweetalert";
import { PermissionContext } from "../permission/PermissionContext";
import { PermitUserContext } from "./PermitUserContext";
import PermitUserList from "./PermitUserList";

const PermitUserModal = () => {
  const { sports, userTypes } = useContext(PermissionContext);
  const { getPermitUserData, setUserType, setGender, setSport, setIsLoading } =
    useContext(PermitUserContext);
  const [formData, setFormData] = useState({
    userType: "",
    sport: "",
    gender: "",
  });
  const [error, setError] = useState("");
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setError("");
  };

  const resetFilter = () => {
    setUserType(null);
    setGender(null);
    setSport(null);
    setFormData({
      userType: "",
      sport: "",
      gender: "",
    });
    console.log("here we are");
  };

  const handleGetData = async () => {
    if (!formData.userType || !formData.gender) {
      setError("Please select User Type and Gender before proceeding.");
      return;
    }

    try {
      setUserType(formData.userType);
      setSport(formData.sport);
      setGender(formData.gender);
      setIsLoading(true);
      const response = await getPermitUserData(
        formData.userType,
        formData.sport,
        formData.gender
      );
      if (!response) {
        swal({
          title: "No Data Found",
          text: "No Permission found for this combination.",
          icon: "warning",
          buttons: {
            cancel: "Close",
          },
        });
      } else {
        console.log("Fetched data:", response.data);
        swal("Success", "Data fetched successfully!", "success");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      swal("Error", "Failed to fetch data. Please try again later.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <div className="basic-form">
            {error && <Alert variant="danger">{error}</Alert>}
            <form>
              <div className="row mb-3 d-flex align-items-end">
                {/* User Type Dropdown */}
                <div className="col-lg-2">
                  <label>
                    Select User Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="userType"
                    onChange={handleChange}
                    value={formData.userType}
                  >
                    <option value="">Select User Type</option>
                    {userTypes.map((type) => (
                      <option
                        key={type.sub_category_id}
                        value={type.sub_category_id}
                      >
                        {type.sub_category_name_view}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Sports Dropdown */}
                <div className="col-lg-2">
                  <label>Select Sports</label>
                  <select
                    className="form-control"
                    name="sport"
                    onChange={handleChange}
                    value={formData.sport}
                  >
                    <option value="">Select Sport</option>
                    {sports.map((type) => (
                      <option key={type.sport_id} value={type.sport_id}>
                        {type.sport_name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Gender Dropdown */}
                <div className="col-lg-2">
                  <label>
                    Select Gender <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="gender"
                    onChange={handleChange}
                    value={formData.gender}
                  >
                    <option value="">Select Gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="3">Both</option>
                  </select>
                </div>

                {/* Get Data Button */}
                <div className="col-md-2">
                  <Button
                    className="btn btn-md btn-primary"
                    onClick={handleGetData}
                  >
                    Get Data
                  </Button>
                </div>
                <div className="col-md-2">
                  <Button
                    className="btn btn-md btn-primary"
                    onClick={resetFilter}
                  >
                    Reset Filter
                  </Button>
                </div>
              </div>
            </form>
            <PermitUserList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermitUserModal;
