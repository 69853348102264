import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import { PermitUserContext } from "./PermitUserContext";
import QRCodeComponent from "./QRcode";

const PermitUserList = () => {
  const {
    page,
    setPage,
    limit,
    setLimit,
    totalPages,
    currentPage,
    permitUserData,
    getPermitUserData,
    isLoading,
    setIsLoading,
  } = useContext(PermitUserContext);
  const navigate = useNavigate();
  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setPage((prev) => prev + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setPage((prev) => prev - 1);
    }
  };
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>Permit Users</h5>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <tbody>
                <tr>
                  <td colSpan={100} className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </div>
          ) : (
            <>
              <div className="pb-2 row">
                <div className="col-md-1">
                  <select
                    className="form-control form-control-sm"
                    value={limit}
                    onChange={(e) => {
                      setPage(1);
                      setLimit(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((value) => (
                      <option key={`${value}`} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="w-100 table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Full Name</th>
                      <th>Gender</th>
                      <th>Sport</th>
                      <th>Venue</th>
                      <th>Zones</th>
                      <th>Colour Code</th>
                      <th>Category Code</th>
                      <th>Functional Code</th>
                      <th>QR Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {permitUserData?.data?.length > 0 ? (
                      permitUserData?.data?.map((item, index) => (
                        <tr key={index}>
                          <td>{(currentPage - 1) * limit + index + 1}</td>
                          <td>{item.full_name || "N/A"}</td>
                          <td>
                            {item.gender == 1
                              ? "Male"
                              : item.gender == 2
                              ? "Female"
                              : item.gender == 3
                              ? "Both"
                              : "N/A"}
                          </td>
                          <td>{item?.sport_name || "N/A"}</td>
                          <td>{item?.venue_name || "N/A"}</td>
                          <td>{item?.zone_names || "N/A"}</td>
                          <td>
                            {item.venue_color_code || "N/A"}
                            <span
                              style={{
                                display: "inline-block",
                                backgroundColor:
                                  item?.venue_color_code || "#ffffff",
                                height: "20px",
                                width: "50px",
                                marginLeft: "5px",
                                borderRadius: "20%",
                                border: "1px solid #000",
                              }}
                            ></span>
                          </td>

                          <td>{item?.category_code || "N/A"}</td>
                          <td>{item?.functionalCode || "N/A"}</td>
                          <td>
                            <QRCodeComponent data={item} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No Users Available for given User Type, Sport and
                          Gender Combination
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(page) => setPage(page)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PermitUserList;
