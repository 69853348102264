import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import axiosInstance from "../../../services/AxiosInstance";
import axios from "axios";

const ManualForm = () => {
  const [subtypes, setSubTypes] = useState([]);
  const [sports, setSports] = useState([]);
  const initialForm = {
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    mobile: "",
    categoryid: "",
    gender: "",
    photo: null,
    sportid: ""
  };
  const [form, setForm] = useState(initialForm);

  const getUserTypes = async () => {
    try {
      const response = await axiosInstance.get("/user-sub-type");
      setSubTypes(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching User Types:", error);
    }
  };

  const getSports = async () => {
    try {
      const response = await axiosInstance.get('/sports-name');
      setSports(response.data.data || []);
    } catch (error) {
      console.error("Error fetching sports:", error);
    }
  };

  useEffect(() => {
    getUserTypes();
    getSports();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setForm((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data:", form);

    const formData = new FormData();
    formData.append("firstname", form.firstname);
    formData.append("middlename", form.middlename || "");
    formData.append("lastname", form.lastname);
    formData.append("email", form.email);
    formData.append("gender", form.gender);
    formData.append("mobile_number", form.mobile);
    formData.append("sports_id", +form.sportid || "");
    formData.append("sub_category_type", form.categoryid);
    formData.append("image", form.photo);

    try {
      const authToken = localStorage.getItem("access_token");

      if (authToken) {
        let apiUrl = `/user/`;
        const response = await axiosInstance.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response?.data?.statusCode === 200) {
          swal("Success", "User created successfully", "success");
          setForm(initialForm);
        } else {
          swal("Error", "Something went wrong. Please try again.", "error");
        }
      } else {
        let apiUrl = `${process.env.REACT_APP_BASE_URL}/user/registrationform`;
        const response = await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response?.data?.statusCode === 200) {
          swal(
            "Success",
            "Registration form submitted to Admin successfully.",
            "success"
          );
          setForm(initialForm);
        } else {
          swal("Error", "Something went wrong. Please try again.", "error");
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      swal(
        "Error",
        error.response?.data?.message || "Failed to create user. Please try again later.",
        "error"
      );
    }
  };
  
  

  return (
    <div className="d-flex justify-content-center" style={{minWidth: "80vw"}}>
      <div className="row justify-content-center h-100 align-items-center" style={{minWidth: "80vw"}}>
        <div className="mt-4 mb-4">
          <div className="authincation-content">
            <div className="row no-gutters">
              <div className="col-xl-12">
                <div
                  className="auth-form"
                style={{ minHeight: "90vh", height: "auto", overflowY: "auto" }}
                >
                  <div className="d-flex justify-content-center align-items-center mb-2">
                    <h3>Registration Form</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4 d-flex justify-content-between align-items-center">
                      <h4>
                        <label>Personal Information</label>
                      </h4>
                      <span className="text-red">
                        * marked fields are required
                      </span>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>
                              First Name<span className="text-red">*</span>
                            </strong>
                          </label>
                          <input
                            type="text"
                            name="firstname"
                            className="form-control"
                            placeholder="First Name"
                            value={form.firstname}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>Middle Name</strong>
                          </label>
                          <input
                            type="text"
                            name="middlename"
                            className="form-control"
                            placeholder="Middle Name"
                            value={form.middlename}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>
                              Last Name<span className="text-red">*</span>
                            </strong>
                          </label>
                          <input
                            type="text"
                            name="lastname"
                            className="form-control"
                            placeholder="Last Name"
                            value={form.lastname}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <strong>
                            Gender<span className="text-red">*</span>
                          </strong>
                          <select
                            name="gender"
                            className="form-control"
                            value={form.gender}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Gender</option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                            <option value="3">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>
                              Email<span className="text-red">*</span>
                            </strong>
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            value={form.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label className="mb-1">
                            <strong>
                              Mobile Number<span className="text-red">*</span>
                            </strong>
                          </label>
                          <input
                            type="number"
                            name="mobile"
                            className="form-control"
                            placeholder="Mobile Number"
                            value={form.mobile}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <strong>
                          Select Sub Category<span className="text-red">*</span>
                          </strong>
                          <select
                            name="categoryid"
                            className="form-control"
                            value={form.categoryid}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select User Sub Type</option>
                            {subtypes.map((type) => (
                              <option
                                key={type.sub_category_id}
                                value={type.sub_category_id}
                              >
                                {type.sub_category_name_view}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                        <strong>
                          Select Sport
                        </strong>
                          <select
                            name="sportid"
                            className="form-control"
                            value={form.sportid}
                            onChange={handleChange}
                          >
                            <option value="">Select Sport</option>
                            {sports.map((sport) => (
                              <option
                                key={sport.sport_id}
                                value={sport.sport_id}
                              >
                                {sport.sport_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <strong>
                            Upload Image<span className="text-red">*</span>
                          </strong>
                          <input
                            type="file"
                            name="photo"
                            className="form-control"
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mb-2">
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualForm;
