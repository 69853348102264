import React from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";

const ViewSubCategoryModal = ({
  viewShowModal,
  setViewShowModal,
  subCategoryData,
}) => {
  return (
    <Modal className="fade" show={viewShowModal}>
      <Modal.Header>
        <Modal.Title>View Sub-Category</Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => setViewShowModal(false)}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="basic-list-group">
          <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between align-items-center">
              <div>
                <ul>
                  <li className="list_of_client">Sub-Category Name</li>
                  <li className="list_of_client">Slug</li>
                  <li className="list_of_client">Category Name</li>
                  <li className="list_of_client">Status</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li className="mb-2">
                    {subCategoryData?.sub_category_name_view || "N/A"}
                  </li>
                  <li className="mb-2">
                    {subCategoryData?.sub_category_name_view_url_slug || "N/A"}
                  </li>
                  <li className="mb-2">
                    {subCategoryData?.parentCategory?.category_name_view || "N/A"}
                  </li>
                  <li className="mb-2">
                    {subCategoryData?.active_status === "1"
                      ? "Active"
                      : "Inactive"}
                  </li>
                </ul>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setViewShowModal(false)} variant="danger light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewSubCategoryModal;
