import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import Pagination from "../permitUsers/Pagination";
import axios from "axios";
import swal from "sweetalert";
import { PermissionContext } from "../permission/PermissionContext";


const PermitUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const authToken = localStorage.getItem("access_token");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const {userTypes} = useContext(PermissionContext)
  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get(
        `/user/registrationform?page=${currentPage}&limit=${limit}`
      );
      console.log("API Response:", response.data);
      if (Array.isArray(response?.data?.data)) {
        setUsers(response?.data?.data);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
        setPage(response?.data?.currentPage);
      } else {
        console.error("Expected an array of users but got:", response.data);
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!authToken) {
      setLoading(false);
      return;
    }

    

    fetchUsers();
  }, [authToken, currentPage, limit]);

  const handleLimitChange = (e) => {
    console.log("Limit changed to", e.target.value);
    setLimit(e.target.value);
  };

  const captureUser = async (item) =>{
    const response = await axiosInstance.post(`user/registrationform/approve/${item.registration_data_id}`);
    if(response.status == 200){
      swal("Success", "User Approved successfully", "success");
      fetchUsers();
    }
  }

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>Permit Users</h5>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <div className="pb-2 row">
                <div className="col-md-1">
                  <select
                    className="form-control form-control-sm"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    {[10, 20, 30, 40, 50].map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="w-100 table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>First Name</th>
                      <th>Middle Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Photo</th>
                      <th>Sub Category</th>
                      <th>Approve</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length > 0 ? (
                      users.map((item, index) => (
                        <tr key={index}>
                          <td>{(currentPage - 1) * limit + index + 1}</td>
                          <td>{item.first_name || "N/A"}</td>
                          <td>{item.middle_name || "N/A"}</td>
                          <td>{item.last_name || "N/A"}</td>
                          <td>{item.email_id || "N/A"}</td>
                          <td>{item.mobile_number || "N/A"}</td>
                          <td>
                            {item.player_image_path ? (
                              <img
                                onClick={() => {
                                  window.open(
                                    `${process.env.REACT_APP_BASE_URL}/uploads/${item.player_image_path}`,
                                    "_blank"
                                  );
                                }}
                                src={`${process.env.REACT_APP_BASE_URL}/uploads/${item.player_image_path}`}
                                alt="User Photo"
                                className="cursor-pointer"
                                style={{ width: "50px", height: "50px" }}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>{userTypes.find((cat)=>cat.sub_category_id == item.sub_category_type)?.sub_category_name_view  || "N/A"}</td>
                          <td>
                            <div className="d-flex" style={{gap: "10px"}}>
                              <button className="btn btn-sm btn-success" onClick={()=>captureUser(item)}>
                                Capture
                              </button>
                              {/* <button className="btn btn-sm btn-danger">Reject</button> */}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No users found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PermitUsers;
