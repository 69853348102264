import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { ListGroup, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";

import PageTitle from "../../../jsx/layouts/PageTitle";
import Editable from "../../../jsx/pages/Editable";
import ClientUploadLogo from "../../../jsx/components/SuperAdmin/client/ClientUploadLogo";
import ClientEditModal from "../../../jsx/components/SuperAdmin/client/ClientEditModal";
import ClientAddModal from "../../../jsx/components/SuperAdmin/client/ClientAddModal";
import {
  getAllCategoryType,
  getAllUserCategory,
  getCategoryTypeById,
} from "../../../services/superadminService/CategoreyType";
import AddCategoryModal from "./AddCategoryModal";
import AddSubCategoryModal from "../subCategory/AddSubCategoryModal";
import CategoryUpdateModal from "./CategoryUpdateModal";
import ViewCategoryModal from "./ViewCategoryModal";
import axiosInstance from "../../../services/AxiosInstance";
import Pagination from "./pagination"; 

const CategoryTypeList = () => {
  const perPage = 10;
  const [addCard, setAddCard] = useState(false);
  const [addsubCard, setAddsubCard] = useState(false);
  const [contents, setContents] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [basicModal, setBasicModal] = useState(false);
  const [categoryViewList, setCategoryViewList] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editContentId, setEditContentId] = useState(null);
  const [viewShowModal, setViewShowModal] = useState(false);
  const [categoryidList, setCategoryidList] = useState({});
  const [userCategory, setUserCategory] = useState([]);
  const [categoryListById, setCategoryListById] = useState({});

  const handleEditClick = (data) => {
    setShowEditModal(true);
    setCategoryListById(data);
  };

  const [editFormData, setEditFormData] = useState({
    name: "",
    department: "",
    gender: "",
    education: "",
    mobile: "",
    email: "",
  });

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const editedContent = {
      id: editContentId,
      name: editFormData.name,
      department: editFormData.department,
      gender: editFormData.gender,
      education: editFormData.education,
      mobile: editFormData.mobile,
      email: editFormData.email,
    };
    const newContents = [...contents];
    const index = contents.findIndex((content) => content.id === editContentId);
    newContents[index] = editedContent;
    setContents(newContents);
    setEditContentId(null);
  };

  const viewClientFun = (data) => {
    setCategoryListById(data);
    setViewShowModal(true);
  };

  const OpenlogoupdateModal = (clientId) => {
    setClientId(clientId);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MMMM Do YYYY");
  };

  const ClientListApi = async (pageNumber = 1) => {
    try {
      const response = await axiosInstance.get(
        `/user-type/all?page=${pageNumber}`
      );
      const data = response?.data?.data || [];
      setClientData(data);
      setFilteredData(data);
      setTotalPages(response?.data?.totalPages || 1);
      setCurrentPage(response?.data?.currentPage || 1);
    } catch (error) {
      console.error("Error fetching category list:", error);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = clientData.filter((data) => {
      const categoryName = data?.category_name_view?.toLowerCase() || "";
      const parentCategory =
        data?.mainCategory?.main_category_name?.toLowerCase() || "";
      const slug = data?.category_name?.toLowerCase() || "";
      const status = data?.active_status ? "active" : "inactive";

      return (
        categoryName.includes(query) ||
        parentCategory.includes(query) ||
        slug.includes(query) ||
        status.includes(query)
      );
    });

    setFilteredData(filtered);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    ClientListApi(pageNumber);
  };

  const getUserListApi = async () => {
    try {
      const response = await axiosInstance.get("/user-type/all");
      setUserCategory(response?.data);
    } catch (error) {
      console.error("Error fetching user categories:", error);
    }
  };

  useEffect(() => {
    getUserListApi();
  }, []);

  useEffect(() => {
    ClientListApi(currentPage);
  }, [currentPage]);

  return (
    <>
      <PageTitle activeMenu="Table" motherMenu="Post" />
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex">
            <div>
              <div className="input-group search-area">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <span className="input-group-text">
                  <Link to="#">
                    <i className="flaticon-381-search-2"></i>
                  </Link>
                </span>
              </div>
            </div>
            <div>
              <Dropdown as={ButtonGroup}>
                <Button variant="primary" onClick={() => setAddCard(true)}>
                  Add Category <i className="fa fa-plus"></i>
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div id="example_wrapper" className="dataTables_wrapper">
                <form onSubmit={handleFormSubmit}>
                  <table id="example" className="display w-100 dataTable">
                    <thead>
                      <tr>
                        <th>Sr.No</th>
                        <th>Category Name</th>
                        <th>Parent Category</th>
                        <th>Slug</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData?.length > 0 ? (
                        filteredData.map((data, index) => (
                          <tr key={index}>
                            <td>{(currentPage - 1) * perPage + index + 1}</td>
                            <td>{data?.category_name_view || "N/A"}</td>
                            <td>
                              {data?.mainCategory?.main_category_name || "N/A"}
                            </td>
                            <td>{data?.category_name || "N/A"}</td>
                            <td>
                              {data?.active_status ? "Active" : "Inactive"}
                            </td>
                            <td>
                              <div className="d-flex">
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  className="me-2"
                                  onClick={() => handleEditClick(data)}
                                >
                                  <i className="fas fa-pen"></i>
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => viewClientFun(data)}
                                >
                                  <i className="fa fa-eye"></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      <AddCategoryModal
        addCard={addCard}
        setAddCard={setAddCard}
        CategoryList={ClientListApi}
        userCategory={userCategory}
      />
      <ViewCategoryModal
        viewShowModal={viewShowModal}
        setViewShowModal={setViewShowModal}
        categoryidList={categoryListById}
      />
      <CategoryUpdateModal
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        categoryViewList={categoryViewList}
        categoryidList={categoryListById}
        CategoryList={ClientListApi}
      />
    </>
  );
};

export default CategoryTypeList;
