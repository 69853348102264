import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import swal from "sweetalert";
import axiosInstance from "../../../../src/services/AxiosInstance"; // Correct axiosInstance import
import Select from "react-select";
import { PermissionContext } from "./PermissionContext";

const EditModal = ({ show, handleClose, editData }) => {
  const [formData, setFormData] = useState({
    userType: "",
    gender: "",
    sport: "",
    venue: "",
    zones: [],
    venueColorCode: "#ffffff", // Use this for color code
    categoryCode: "",
    status: "0", // Default to Enabled
  });
  const { userTypes, sports, venues, getPermissionData } =
    useContext(PermissionContext);
  const [availableZones, setAvailableZones] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Fetch zones based on the selected venue
    if (formData.venue) {
      const fetchZones = async () => {
        try {
          const response = await axiosInstance.get(
            `/venue/${formData.venue}/zones`
          );
          const zonesData = response.data.data.zones.map((zone) => ({
            value: zone.id,
            label: zone.name,
          }));
          setAvailableZones([
            { value: "infinity", label: "Infinity" },
            ...zonesData,
          ]);
        } catch (error) {
          console.error("Error fetching zones:", error);
        }
      };
      fetchZones();
    }
  }, [formData.venue]);

  useEffect(() => {
    // Set form data based on editData
    if (editData) {
      setFormData({
        userType: editData?.user_category_sub_type?.sub_category_id || "",
        gender: editData.gender || "",
        sport: editData?.ref_sports_name?.sport_id || null ,
        venue: editData.venue_id || "",
        zones:
          editData?.Zone?.map((zone) => ({ value: zone?.id, label: zone?.name })) ||
          [],
        venueColorCode: editData.venue_color_code || "#ffffff",
        categoryCode: editData.category_code || "",
        status: editData.status || "0", // Default to Enabled if not provided
      });
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleZoneChange = (selectedOptions) => {
    if (selectedOptions.some((zone) => zone.value === "infinity")) {
      setFormData((prev) => ({
        ...prev,
        zones: availableZones.filter((zone) => zone.value !== "infinity"),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        zones: selectedOptions,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.zones.length)
      newErrors.zones = "At least one zone must be selected.";
    if (!formData.venueColorCode)
      newErrors.venueColorCode = "Color Code is required.";
    if (!formData.categoryCode)
      newErrors.categoryCode = "Category Code is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const updatedData = {
        zoneIds: formData.zones.map((zone) => zone.value),
        sportsNameId: formData.sport,
        gender: formData.gender,
        userType: formData.userType,
        categoryCode: formData.categoryCode,
        venueColorCode: formData.venueColorCode,
        status: formData.status, // Include status in the API payload
      };

      const response = await axiosInstance.put(
        `/permission/${editData.id}`,
        updatedData
      );
      if (response.status === 200) {
        swal("Success", "Permission updated successfully!", "success");
        handleClose();
        getPermissionData(); // Refresh the list
      } else {
        swal(
          "Error",
          "Failed to update permission. Please try again later.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error updating permission:", error);
      swal(
        "Error",
        "Failed to update permission. Please try again later.",
        "error"
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Permission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            {/* User Type */}
            <Col md={6}>
              <Form.Group>
                <Form.Label>User Type</Form.Label>
                <select
                  className="form-control"
                  name="userType"
                  onChange={handleChange}
                  value={formData.userType}
                >
                  <option value="">Select User Type</option>
                  {userTypes.map((type) => (
                    <option
                      key={type.sub_category_id}
                      value={type.sub_category_id}
                    >
                      {type.sub_category_name_view}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>

            {/* Gender */}
            <Col md={6}>
              <Form.Group>
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Both</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            {/* Sport */}
            <Col md={6}>
              <Form.Group>
                <Form.Label>Select Sport</Form.Label>
                <Form.Control
                  as="select"
                  name="sport"
                  value={formData.sport}
                  disabled
                >
                  <option value="">Select Sport</option>
                  {sports?.map((type) => (
                    <option key={type.sport_id} value={type.sport_id}>
                      {type.sport_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            {/* Venue */}
            <Col md={6}>
              <Form.Group>
                <Form.Label>Select Venue</Form.Label>
                <Form.Control
                  type="text"
                  name="venue"
                  value={
                    venues.find((v) => v.value === formData.venue)?.label || ""
                  }
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {/* Zones */}
            <Col md={12}>
              <Form.Group style={{ marginBottom: "15px" }}>
                <Form.Label>Select Zone</Form.Label>
                <Select
                  isMulti
                  options={availableZones}
                  placeholder="Select Zone"
                  onChange={handleZoneChange}
                  value={formData.zones}
                />
                {errors.zones && <Alert variant="danger">{errors.zones}</Alert>}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {/* Color Code */}
            <Col md={12}>
              <Form.Group style={{ marginBottom: "15px" }}>
                <Form.Label>
                  Color Code <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="color"
                  name="venueColorCode"
                  value={formData.venueColorCode}
                  onChange={handleChange}
                  className="w-100"
                />
                {errors.venueColorCode && (
                  <Alert variant="danger">{errors.venueColorCode}</Alert>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            {/* Category Code */}
            <Col md={6}>
              <Form.Group style={{ marginBottom: "15px" }}>
                <Form.Label>
                  Category Code <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="categoryCode"
                  value={formData.categoryCode}
                  onChange={handleChange}
                  placeholder="Enter Category Code"
                />
                {errors.categoryCode && (
                  <Alert variant="danger">{errors.categoryCode}</Alert>
                )}
              </Form.Group>
            </Col>

            {/* Status */}
            <Col md={6}>
              <Form.Group style={{ marginBottom: "15px" }}>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <option value="0">Enabled</option>
                  <option value="1">Disabled</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
