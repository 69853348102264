import React, { useEffect, useState } from "react";
import VenueModal from "./venueModal";
import { Button } from "react-bootstrap";
import VenueList from "./venueList";
import PageTitle from "../../../../jsx/layouts/PageTitle";
import axiosInstance from "../../../../services/AxiosInstance";


function Venue() {
  const [showVenueModal, setShowVenueModal] = React.useState(false);
  const[data,setData]=useState([])
  const handleClose = () => setShowVenueModal(false);
  const handleShow = () => setShowVenueModal(true);
  const fetchData = async () => {
    try {
      const res = await axiosInstance.get("/venue");
      setData(res.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchVenueById = async (id) => {
    try {
      const res = await axiosInstance.get(`/venue/${id}`);
      return res.data.data;
    } catch (error) {
      console.error("Error fetching venue:", error);
      return null; 
    }
  };  
  useEffect(()=>{
    fetchData();
  },[])
  return (
    <>
      <PageTitle
        activeMenu="List"
        motherMenu="Venue"
        Button={() => <Button onClick={handleShow}>Add Venue</Button>}
      />
      <div className="d-flex justify-content-end mb-3">
        <VenueModal
          show={showVenueModal}
          handleClose={handleClose}
          fetchData={fetchData}
          data={data}
          setData={setData}
        />
      </div>
      <div className="venue-list">
        <VenueList fetchData={fetchData} data={data} setData={setData} />
      </div>
      
      {/* <div className="venue-preview mt-4">
        <SingleVenuePreview />
      </div> */}
    </>
  );
}

export default Venue;
