import React, { useEffect, useState } from "react";
import ZoneModal from "./zonesModal";
import { Button } from "react-bootstrap";
import ZoneList from "./zoneslist";
import PageTitle from "../../../../jsx/layouts/PageTitle";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";

function Zones() {
  const [showZoneModal, setShowZoneModal] = React.useState(false);
  const [zones, setZones] = useState([]);
  const [activePage, setActivePage] = useState(1); 
  const [totalPages, setTotalPages] = useState(1); 
  const [itemsPerPage] = useState(10); 
  const { id } = useParams(); 

  const handleClose = () => setShowZoneModal(false);

  const fetchZones = async () => {
    try {
      const res = await axiosInstance.get(`/venue/${id}/zones`, {
        params: {
          page: activePage,
          limit: itemsPerPage,
        },
      });
      const { data } = res.data;
      setZones(data.zones || []);
      setTotalPages(res.data.totalPages || 1);
    } catch (error) {
      console.error("Error fetching zones:", error);
    }
  };

  

  useEffect(() => {
    fetchZones();
  }, [id, activePage]); 

  return (
    <>
      <PageTitle
        activeMenu={`List `} 
        motherMenu="Zones"
        // Button={() => <Button onClick={handleShow}>Add Zone</Button>}
      />
      <div className="d-flex justify-content-end mb-3">
        <ZoneModal
          show={showZoneModal}
          handleClose={handleClose}
          fetchZones={fetchZones}
          venueId={id}
        />
      </div>
      <div className="zone-list">
        <ZoneList
          venueId={id}
          fetchZones={fetchZones}
          zones={zones}
          setZones={setZones}
          activePage={activePage}
          setActivePage={setActivePage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
        />
      </div>
    </>
  );
}

export default Zones;
