import React, { useState } from "react";
import Select from "react-select";
import user from "../../../images/profile/pic1.jpg";
import swal from "sweetalert";
const intialValue = {
  name: "John",
  surname: "Brahim",
  specialty: "Developer",
  skills: "Javelin Throw",
};
const inputBlog = [
  { label: "Name", value: "Neeraj" },
  { label: "Surname", value: "Chopra" },
  { label: "Specialty", value: "Athtlete" },
  { label: "Skills", value: "Javelin Throw" },
];

const options2 = [
  { value: "1", label: "Select" },
  { value: "2", label: "Male" },
  { value: "3", label: "Female" },
  { value: "4", label: "Other" },
];

const options3 = [
  { value: "1", label: "Russia" },
  { value: "2", label: "Canada" },
  { value: "3", label: "China" },
  { value: "4", label: "India" },
];

const options4 = [
  { value: "1", label: "Krasnodar" },
  { value: "2", label: "Tyumen" },
  { value: "3", label: "Chelyabinsk" },
  { value: "4", label: "Moscow" },
];

const ProfileComp = () => {
  const [profileImage, setProfileImage] = useState(
    "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202108/AP21219464016565_1200x768.jpeg?size=690:388s"
  );
  const [userData, setUserData] = useState(intialValue);

  const handleChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    swal("Saved", "", "success");
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <div className="card card-bx profile-card author-profile m-b30">
            <div className="card-body">
              <div className="p-5">
                <div className="author-profile">
                  <div
                    className="author-media"
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "200px",
                    }}
                  >
                    <img
                      src={profileImage}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                    <div
                      className="upload-link"
                      title="Update"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      }}
                    >
                      <input
                        type="file"
                        className="update-file"
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                      <i
                        className="fa fa-camera"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          document.querySelector(".update-file").click()
                        }
                      ></i>
                    </div>
                  </div>
                  <div className="author-info">
                    <h6 className="title">Neeraj Chopra</h6>
                    <span>Athlete</span>
                  </div>
                </div>
              </div>
              <div className="info-list">
                <ul>
                  <li></li>
                  {/* <li>
                    <Link to={"/app-profile"}>Models</Link>
                    <span>36</span>
                  </li>
                  <li>
                    <Link to={"/uc-lightgallery"}>Gallery</Link>
                    <span>3</span>
                  </li>
                  <li>
                    <Link to={"/app-profile"}>Lessons</Link>
                    <span>1</span>






















                  </li> */}
                </ul>
              </div>
            </div>
            <div className="card-footer">
              <div className="input-group mb-3">
                {/* <div className="form-control rounded text-center">
                  Portfolio
                </div> */}
              </div>
              <div className="input-group">
                {/* <a
                  href="https://www.dexignzone.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="form-control text-primary rounded text-start"
                >
                  https://www.dexignzone.com/
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8">
          <div className="card profile-card card-bx m-b30">
            {/* <div className="card-header">
              <h6 className="title"> Account Setup </h6>
            </div> */}
            <form className="profile-form">
              <div className="card-body">
                <div className="row">
                  {inputBlog?.map((item, ind) => (
                    <div className="col-sm-6 m-b30" key={ind}>
                      <label className="form-label">{item.label}</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={item.value}
                      />
                    </div>
                  ))}
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Gender</label>
                    <Select
                      options={options2}
                      className="custom-react-select"
                      defaultValue={options2[0]}
                      isSearchable={false}
                    />
                  </div>
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Birth</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="dd. mm .yyyy"
                    />
                  </div>
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="+123456789"
                    />
                  </div>
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Email address</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="demo@gmail.com"
                    />
                  </div>
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Country</label>
                    <Select
                      options={options3}
                      className="custom-react-select"
                      defaultValue={options3[0]}
                      isSearchable={false}
                    />
                  </div>
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">City</label>
                    <Select
                      options={options4}
                      className="custom-react-select"
                      defaultValue={options4[0]}
                      isSearchable={false}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer justify-content-end">
                {/* <Link to="#" className="btn-link">
                  Forgot your password?
                </Link> */}
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileComp;
