import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../services/AxiosInstance";
import VenueModal from "./venueModal";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const VenueList = ({ fetchData, data = [], setData }) => {
  const [showVenueModal, setShowVenueModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activePage, setActivePage] = useState(1); 
  const itemsPerPage = 10; 

  const navigate = useNavigate();

  const handleClose = () => setShowVenueModal(false);
  const handleShow = () => setShowVenueModal(true);

  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleDeleteModalShow = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    fetchData();
  }, [activePage]); 

  const editVenue = (item) => {
    setEditData(item);
    setShowVenueModal(true);
  };

  const handleViewZones = (venueId) => {
    navigate(`/venue/${venueId}/zones`);
  };

  const handleDeleteVenue = async () => {
    try {
      await axiosInstance.delete(`/venue/${deleteId}`);
      setData((prevData) => prevData.filter((venue) => venue._id !== deleteId));
      setDeleteId(null);
      setShowDeleteModal(false);
      swal("Venue Deleted!", "", "success");
    } catch (error) {
      console.error("Error deleting venue:", error);
    }
  };

  const paginatedData = data.slice(
    (activePage - 1) * itemsPerPage,
    activePage * itemsPerPage
  );

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (activePage > 1) setActivePage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (activePage < totalPages) setActivePage((prev) => prev + 1);
  };

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="w-100 table-responsive">
              <table id="example" className="display w-100 dataTable">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Venue Code</th>
                    <th>Venue Name</th>
                    <th>Capacity</th>
                    <th>Venue Owner Name</th>
                    <th>Image</th>
                    <th>Edit</th>
                    <th>Show Zones</th>
                    <th>Delete</th>
                  </tr>
                </thead>

                <tbody>
                  {paginatedData
                  .reverse()
                  .map((item, index) => (
                    <tr key={item.id}>
                      <td>
                      {(activePage - 1) * itemsPerPage + index + 1}
                      </td>
                      <td>{item.venue_code}</td>
                      <td>{item.name}</td>
                      <td>{item.capacity}</td>
                      <td>{item.venue_owner_name}</td>
                      <td>
                        {item.imageURL ? (
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}/uploads/${item.imageURL}`}
                            alt="Venue"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}/uploads/${item.imageURL}`, "_blank")}
                          />  
                        ) : (
                          "No Image"
                        )}
                      </td>
                      <td>
                        <i
                          className="fas fa-pen cursor-pointer"
                          onClick={() => editVenue(item)}
                        ></i>
                      </td>
                      <td>
                        <i
                          className="bi bi-eye-fill text-primary cursor-pointer"
                          onClick={() => handleViewZones(item.id)}
                        ></i>
                      </td>
                      <td>
                        <i
                          className="fas fa-trash text-danger cursor-pointer"
                          onClick={() => handleDeleteModalShow(item.id)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pagination-controls d-flex align-items-center justify-content-center gap-2 mt-3">
                <Button
                  variant="primary"
                  disabled={activePage === 1}
                  onClick={handlePreviousPage}
                  className="px-3"
                >
                  Previous
                </Button>
                {Array.from(
                  { length: Math.min(5, totalPages) },
                  (_, i) => i + activePage - Math.floor((activePage - 1) % 5)
                )
                  .filter((page) => page > 0 && page <= totalPages)
                  .map((page) => (
                    <Button
                      key={page}
                      variant={page === activePage ? "dark" : "light"}
                      className="px-3"
                      onClick={() => setActivePage(page)}
                    >
                      {page}
                    </Button>
                  ))}
                <Button
                  variant="primary"
                  disabled={activePage === totalPages}
                  onClick={handleNextPage}
                  className="px-3"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showVenueModal && (
        <VenueModal
          show={showVenueModal}
          editData={editData}
          handleClose={handleClose}
          fetchData={fetchData}
        />
      )}

      {showDeleteModal && (
        <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this venue? This action cannot be
            undone.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteModalClose}>
              No
            </Button>
            <Button
              variant="danger"
              onClick={async () => {
                try {
                  await handleDeleteVenue();
                  fetchData();
                  handleDeleteModalClose();
                } catch (error) {
                  console.error("Error during delete operation:", error);
                  swal("Error!", "Failed to delete the venue.", "error");
                }
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default VenueList;
