import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import axiosInstance from "../../../../services/AxiosInstance";
import { useParams } from "react-router-dom";

const initialZoneData = {
  zone_code: "",
  name: "",
  latitude: "",
  longitude: "",
  capacity: "",
  range: "",
  description: "",
};

const ZoneModal = ({ show, handleClose, editData, fetchZones }) => {
  const [zoneData, setZoneData] = useState(initialZoneData);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    if (editData) {
      setZoneData({
        zone_code: editData.zone_code || "",
        name: editData.name || "",
        latitude: editData.latitude || "",
        longitude: editData.longitude || "",
        capacity: editData.capacity || "",
        range: editData.range || "",
        description: editData.description || "",
      });
      setFileUrl(editData.image || null);
    } else {
      setZoneData(initialZoneData);
      setFileUrl(null);
    }
    setErrors({});
  }, [editData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setZoneData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); 
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      setFileUrl(URL.createObjectURL(e.target.files[0]));
    } else {
      setSelectedFile(null);
      setFileUrl(null);
    }
  };

  const handleFilePreview = () => fileUrl && window.open(fileUrl, "_blank");

  const handleReset = () => {
    setZoneData(initialZoneData);
    setSelectedFile(null);
    setFileUrl(null);
    setErrors({});
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!zoneData.zone_code) validationErrors.zone_code = "Zone Code is required.";
    if (!zoneData.name) validationErrors.name = "Zone Name is required.";
    if (!/^(-?[0-9]{1,3}(?:\.\d{1,6})?)$/.test(zoneData.latitude)) {
      validationErrors.latitude = "Latitude must be a valid number between -90 and 90.";
    }
    if (!/^(-?[0-9]{1,3}(?:\.\d{1,6})?)$/.test(zoneData.longitude)) {
      validationErrors.longitude = "Longitude must be a valid number between -180 and 180.";
    }
    if (!zoneData.capacity || isNaN(zoneData.capacity) || parseInt(zoneData.capacity) <= 0) {
      validationErrors.capacity = "Capacity must be a positive number.";
    }
    if (!zoneData.range || isNaN(zoneData.range) || parseInt(zoneData.range) <= 0) {
      validationErrors.range = "Range must be a positive number.";
    }
    if (!zoneData.description) validationErrors.description = "Description is required.";
    if (!selectedFile && !editData) validationErrors.image = "Image is required.";

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      swal("Validation Error", "Please fix the highlighted errors in the form.", "error");
      return;
    }

    const payload = { ...zoneData, venue_id: id };

    try {
      const formData = new FormData();

      if (selectedFile) {
        formData.append("image", selectedFile);
      }

      Object.entries(payload).forEach(([key, value]) =>
        formData.append(key, value)
      );

      if (editData) {
        await axiosInstance.put(`/zones/${editData.id}`, formData);
        swal("Zone Updated!", "The zone has been updated successfully.", "success");
      } else {
        await axiosInstance.post("/zones", formData);
        swal("Zone Created!", "The new zone has been created successfully.", "success");
      }
      fetchZones();
      handleClose();
    } catch (error) {
      const message = error.response?.data?.message || "An unexpected error occurred.";
      swal("Error occurred!", message, "error");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">
            {editData ? "Edit Zone" : "Add Zone"}
          </h4>
          <button type="button" className="btn-close" onClick={handleClose}>
            <span></span>
          </button>
        </div>
        <div className="modal-body">
          <div className="add-contact-box">
            <div className="add-contact-content">
              {/* Zone Code and Zone Name */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Zone Code <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="zone_code"
                    value={zoneData.zone_code}
                    onChange={handleInputChange}
                    isInvalid={!!errors.zone_code}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.zone_code}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Zone Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={zoneData.name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              {/* Latitude and Longitude */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Latitude <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="latitude"
                    value={zoneData.latitude}
                    onChange={handleInputChange}
                    isInvalid={!!errors.latitude}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.latitude}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Longitude <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="longitude"
                    value={zoneData.longitude}
                    onChange={handleInputChange}
                    isInvalid={!!errors.longitude}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.longitude}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              {/* Capacity and Range */}
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Capacity <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="capacity"
                    value={zoneData.capacity}
                    onChange={handleInputChange}
                    isInvalid={!!errors.capacity}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.capacity}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    Range (in meters) <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="range"
                    value={zoneData.range}
                    onChange={handleInputChange}
                    isInvalid={!!errors.range}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.range}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              {/* Description */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Description <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={zoneData.description}
                  onChange={handleInputChange}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              {/* File Input */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Image <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="file"
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={handleFileChange}
                    isInvalid={!!errors.image}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.image}
                  </Form.Control.Feedback>
                  <Button
                    variant="primary"
                    className="ms-2"
                    disabled={!fileUrl && !selectedFile}
                    onClick={handleFilePreview}
                  >
                    Preview
                  </Button>
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button variant="secondary" onClick={handleReset}>
            Reset
          </Button>
          <Button type="submit" variant="primary">
            {editData ? "Update Zone" : "Save Zone"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ZoneModal;
