import React, { useState, useEffect, useContext } from "react";
import { Button, Alert } from "react-bootstrap";
import swal from "sweetalert";
import { PermissionContext } from "./PermissionContext";
import PermissionList from "./PermissionList";
import CreateModal from "./CreateModal";

const PermissionModal = () => {
  const [showModal, setShowModal] = useState(false);
  const {
    sports,
    permissionList,
    setPermissionList,
    userTypes,
    getPermissionData,
  } = useContext(PermissionContext);

  const [formData, setFormData] = useState({
    userType: "",
    sport: null,
    gender: "",
  });
  const [error, setError] = useState("");

  const openModal = () => {
    if (!formData.userType || !formData.gender) {
      swal(
        "Incomplete Form",
        "Please select User Type and Gender before proceeding.",
        "warning"
      );
      return;
    }
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setError("");
  };

  const handleGetData = async () => {
    if (!formData.userType || !formData.gender) {
      swal(
        "Incomplete Form",
        "Please select User Type and Gender before proceeding.",
        "warning"
      );
      return;
    }

    try {
      const response = await getPermissionData(
        formData.userType,
        formData.sport,
        formData.gender
      );
      if (response.data?.length === 0) {
        swal({
          title: "No Data Found",
          text: "There is no matching permission in the list. Would you like to create a new permission?",
          icon: "warning",
          buttons: {
            cancel: "Close",
            confirm: {
              text: "Create Permission",
              value: true,
              visible: true,
              className: "btn-success",
            },
          },
        }).then((value) => {
          if (value) {
            setShowModal(true);
          }
        });
      } else {
        swal("Success", "Data fetched successfully!", "success");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      swal("Error", "Failed to fetch data. Please try again later.", "error");
    }
  };

  const handleReset = async () => {
    setFormData({
      userType: "",
      sport: null,
      gender: "",
    });

    setError("");

    try {
      const response = await getPermissionData();
      setPermissionList(response);
      swal("Success", "Filters reset and all data fetched.", "success");
    } catch (error) {
      console.error("Error resetting data:", error);
      swal(
        "Error",
        "Failed to reset and fetch data. Please try again later.",
        "error"
      );
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <div className="basic-form">
            {error && <Alert variant="danger">{error}</Alert>}
            <form>
              <div className="row mb-3 d-flex align-items-end">
                {/* User Type Dropdown */}
                <div className="col-lg-2">
                  <label>
                    Select User Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="userType"
                    onChange={handleChange}
                    value={formData.userType}
                  >
                    <option value="">Select User Type</option>
                    {userTypes.map((type) => (
                      <option
                        key={type.sub_category_id}
                        value={type.sub_category_id}
                      >
                        {type.sub_category_name_view}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Sports Dropdown */}
                <div className="col-lg-2">
                  <label>Select Sports</label>
                  <select
                    className="form-control"
                    name="sport"
                    onChange={handleChange}
                    value={formData.sport}
                  >
                    <option value="">Select Sport</option>
                    {sports.map((type) => (
                      <option key={type.sport_id} value={type.sport_id}>
                        {type.sport_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-2">
                  <label>
                    Select Gender <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="gender"
                    onChange={handleChange}
                    value={formData.gender}
                  >
                    <option value="">Select Gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="3">All</option>
                  </select>
                </div>

                {/* Get Data Button */}
                <div className="col-md-2">
                  <Button
                    className="btn btn-md btn-primary"
                    onClick={handleGetData}
                  >
                    Get Data
                  </Button>
                </div>

                {/* Reset Button */}
                <div className="col-md-2">
                  <Button
                    className="btn btn-md btn-warning"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </div>

                {/* Create Permission Button */}
                <div className="col-md-2">
                  <Button
                    className="btn btn-md btn-success"
                    onClick={openModal}
                  >
                    Create Permission
                  </Button>
                </div>
              </div>
            </form>

            <PermissionList />
          </div>
        </div>
      </div>

      <CreateModal
        show={showModal}
        handleClose={closeModal}
        userType={formData.userType}
        gender={formData.gender}
        sport={formData.sport}
      />
    </div>
  );
};

export default PermissionModal;
